<template>
  <div>
    <p class="qa_title">1. 哪些行为会被系统判定为切屏?</p>
    <p class="qa_content">
      系统弹窗（包含广告、电脑系统提示、社交消息弹窗等）、浏览器退出全屏、最小化浏览器、点击浏览器窗口外的其他地方、使用浏览器页面翻译工具、使用聊天软件，同时开启除谷歌浏览器外的其他浏览器（例如360浏览器等），开启安全卫士等杀毒软件、电脑管家软件等。Mac OS自带的下拉鼠标出现menu bar功能也会被系统判为切屏。
    </p>
    <p class="qa_title" style="margin-top: 12px">2. 切屏多少次算作弊？达到切屏上限后会怎么样？ </p>
    <p class="qa_content">系统不允许切屏，若检测到切屏行为系统将自动记录，并根据用户的切屏数量、频次、间隔等判断是否存在作弊行为。若被系统判断为作弊行为，系统将会强制自动交卷，无论你是否答完所有问题。</p>

    <p class="qa_title" style="margin-top: 12px">3. 页面上为什么不显示鼠标光标？</p>
    <p class="qa_content">为避免选手使用鼠标在页面中使用相关插件取词翻译，当鼠标光标停留在卷面上时将为隐藏状态，将鼠标移动至选项前的圆圈即可选择对应选项。
鼠标光标在切换题目时（上一题/下一题）可正常显示。</p>

    <p class="qa_title" style="margin-top: 12px">4. 题目中提到的材料/图表在哪里查看？</p>
    <p class="qa_content">若题目中有提及需要根据材料或图表作答，可在页面考试题目的左侧部分点击“上一页/下一页”翻页查看。</p>

    <p class="qa_title" style="margin-top: 12px">5. 考试过程中是否可以去洗手间？</p>
    <p class="qa_content">考试过程中不允许离开摄像头去洗手间，不允许自行关闭摄像头。</p>

    <p class="qa_title" style="margin-top: 12px">6. 考试是否可以提前交卷？</p>
    <p class="qa_content">考试结束前15分钟可提前交卷。</p>

    <p class="qa_title" style="margin-top: 12px">7. 页面突然空白/黑屏/卡顿，能不能刷新页面？</p>
    <p class="qa_content">可以刷新，选手所在的考试环境中，网络正常流畅的状态下，答题记录将实时保存，刷新前请务必先检查确认网络状态。</p>

    <p class="qa_title" style="margin-top: 12px">8. 因误触关闭页面怎么办？答题记录会不会没保存？</p>
    <p class="qa_content">如误触关闭页面，可回到首页后再次进入考试；选手所在的考试环境中，网络正常流畅的状态下，答题记录将实时保存，再次进入考试后请第一时间检查每道题的答题状态。</p>

    <p class="qa_title" style="margin-top: 12px">9. 为什么我的页面总题数不足正式考试题数？</p>
    <p class="qa_content">若页面总题数较少，不足正式考试题数，可能为误进入Mock考试，需回到考试系统首页，刷新页面。在【挑战项目】中查看到考试显示为“Formal Test”，即可进入正式考试。</p>

    <p class="qa_title" style="margin-top: 12px">10. 系统考试时间倒计时是零怎么办？</p>
    <p class="qa_content">如果没有显示倒计时但是可以正常做题，在保证网络正常的前提下，可尝试刷新页面或直接查看电脑时间自行计时。</p>

  </div>
</template>

<script>
export default {
  name: "QA"
}
</script>

<style scoped>
.qa_title {
  color: #484949;
  font-size: 16px;
  font-weight: bold;
}

.qa_content {
  margin-top: 10px;
  color: #707170;
  font-size: 14px;
  line-height: 22px;
}

</style>